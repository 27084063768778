import { extendObservable } from "mobx";

import AdministratorAPI from "../services/AdministratorAPI";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";

import { cryptoPass } from "~/helpers/utils/Functions";
import EuroAdministratorModel from "~/models/euro/EuroAdministratorModel";
import StorageUtil, { KEYS } from "~/helpers/utils/StorageUtil";
import AdministratorModel from "~/models/AdministratorModel";

class AdministradorStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      administrator: undefined,
      administrators: [],
      staffs: [],
      loading: false,
      superAdmin: false,
      totalPages: 0,
      size: 20,
      page: 0,
      sort: "name",
    });
  }

  /** get Current user. */
  get user() {
    return this.userStore.user;
  }

  get userStore() {
    return this.rootStore.usersStore;
  }

  /**Retorna merchanto do usuário */
  get userMerchant() {
    return this.userStore.userMerchant;
  }

  get isSuperAdmin() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === "SUPERADMIN" || currentAuth === "STOOM";
  }

  reset() {
    this.administrator = undefined;
  }

  /**Atualiza uma propriedade do novo adminstrator*/
  async updatePropAdministrator(prop, value) {
    const account = this.administrator
      ? this.administrator
      : new AdministratorModel();
    switch (prop) {
      case "username":
        account[prop] = value;
        break;
      case "email":
        account[prop] = value;
        break;
      case "pass":
        account.password = value;
        break;
      case "new-password":
        account.newPassword = value;
        break;
      default:
        account[prop] = value;
    }
    this.administrator = new AdministratorModel(account);

    console.log(this.administrator);
  }

  /**Cria um novo usuário adminin */
  async createAdministrator() {
    this.loading = true;
    if (!this.isSuperAdmin) this.administrator.merchant = this.userMerchant;
    const data = JSON.stringify(this.administrator);
    const response = await AdministratorAPI.createAdministrator(data);
    this.loading = false;
    if (!response.error) {
      this.administrator = new AdministratorModel();
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Usuário criado com sucesso."
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 20, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = "name";

    sort ? (this.sort = sort) : (this.sort = "name");

    await this.getList();
    return true;
  }

  /**Busca todos os usuários addmins */
  async getList(size = 15, page = this.page, sort = this.sort) {
    this.loading = true;
    const response = await AdministratorAPI.getAllAdministrators({
      size,
      page,
      sort,
    });
    this.loading = false;
    if (response.error) return [];
    this.administrators =
      response?.content?.map((usr) => new AdministratorModel(usr)) ?? [];
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  /**
   * Busca todas as informações de um usuário administrador.
   * @param  {string} userName
   */
  async get(userName) {
    this.loading = true;
    const response = await AdministratorAPI.getUserAdmin(userName);
    this.loading = false;
    if (!response.error) {
      this.administrator = new AdministratorModel(response);
      return this.administrator;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**
   * Busca todas as informações de um usuário administrador.
   * @param  {string} userName
   */
  async getAdminLogin(user) {
    this.administrator = new AdministratorModel(user);
    this.superAdmin = this.administrator.isSuperAdmin;
    return this.administrator;
  }

  /**Busca um usuário customer por um parametro. */
  async getAdminByQuery(value, param = "uuid") {
    this.loading = true;
    const response = await AdministratorAPI.getAdminBy(param, value);
    if (!response.error) {
      this.administrator = new AdministratorModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca um usuário customer por um parametro. */
  async update() {
    this.loading = true;
    const data = this.administrator.name;

    const response = await AdministratorAPI.updateAdministrator(data);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Informações atualizadas com sucesso!"
      );
      this.administrator = new AdministratorModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Deleta administrador */
  async delete(uuid) {
    this.loading = true;
    const response = await AdministratorAPI.deleteAdministrator(uuid);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Administrador excluído com sucesso!"
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Change Password*/
  async changePassword() {
    const data = {
      newPassword: this.administrator.newPassword,
      email: this.administrator.email,
    };
    this.loading = true;
    const response = await AdministratorAPI.changePassword(data);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, "Senha Alterada com sucesso");
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  getListSelect(administrators = this.administrators) {
    return administrators
      .filter((a) => !a.isSuperAdmin)
      .map((a) => ({ value: a.uuid, label: a.name }));
  }
}
export default AdministradorStore;
