import BaseAPI, { URLS } from "./BaseAPI";

class EvaluationsAPI {
  /**Retorno generico de excessões */
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * @param  {object} params - parametros de paginação
   */
  static async getTresureUuidEvaluation(uuid) {
    try {
      const response = await BaseAPI.get(
        `${URLS.EVALUATIONS}/treasures/${uuid}`
      );
      if (response.status === 200) return response.data;
      return console.log("Falha ao buscar Avaliações");
    } catch (e) {
      return this._exception(e, "Falha ao buscar lista comentarios");
    }
  }

  /**
   * Aprovação de comentario
   * @param  {string} uuid
   */
  static async approveEvaluations(uuid) {
    try {
      const response = await BaseAPI.post(
        `${URLS.MANAGER}/evaluations/${uuid}/approve?approved=true&admin=ac3c9d13-463a-41b6-9904-15689dd2c44d`
      );
      if (response.status === 200) return response.data;
      return {
        error: "Não foi possível aprovar o comentario",
      };
    } catch (e) {
      return this._exception(e, "Não foi possível aprovar o comentario");
    }
  }

  /**
   * Request que lista os Tesouros
   * @param {Object} params São os parametros da busca, por exemplo pagina e items por página;
   */
  static async getList(params) {
    const url = `${URLS.EVALUATIONS}/find/`;
    try {
      const response = await BaseAPI.get(url, params);

      if (response.status === 200) return response.data;
      return { error: "Erro inesperado ao buscar Tesouros" };
    } catch (e) {
      return this._exception(e, "Falha ao listar tesouros");
    }
  }

  /**
   * Aprovação de comentario
   * @param  {string} uuid
   */
  static async editEvaluationsTreasure(data) {
    const evaluationsData = {
      question: data.question,
    };

    try {
      const response = await BaseAPI.put(
        `${URLS.EVALUATIONS}/${data.uuid}`,
        evaluationsData
      );
      if (response.status === 200) return response.data;
      return {
        error: "Não foi possível editar a avaliação",
      };
    } catch (e) {
      return this._exception(e, "Não foi possível editar a avaliação");
    }
  }
}

export default EvaluationsAPI;
