import BaseAPI, { URLS } from "./BaseAPI";

class AdministradorAPI {
	/**
	 * @param  {Object} data Dados do novo usuário administrador
	 */
	static async createAdministrator(data) {
		try {
			const response = await BaseAPI.post(URLS.ACCOUNTS, data);
			return response.status === 201
				? response
				: { error: "Falha ao cadastrar admin" };
		} catch (e) {
			console.log(e.message);
			return { error: "Falha ao cadastrar Administrador" };
		}
	}

	/**
	 * @param  {string} uuid Uuid do usuário que será editado
	 * @param {object} data dados do usuário que será editado
	 */
	static async updateAdministrator(data) {
		try {
			const response = await BaseAPI.put(`${URLS.ACCOUNTS}/${data}`,);
			if (response.status === 200) return response.data;
			return { error: "Falha ao atualizar informações do usuário" };
		} catch (e) {
			console.log(e);
			return { error: e.message };
		}
	}

	/**
	 * @param  {string} uuid Uuid do usuário que será excluído
	 */
	static async deleteAdministrator(uuid) {
		try {
			const response = await BaseAPI.delete(`${URLS.ACCOUNTS}/${uuid}`);
			if (response.status === 200) return response.data;
			return { error: "Não foi possível excluir o administrador" };
		} catch (e) {
			console.log(e);
			return { error: e.message };
		}
	}

	/**Monta uma query de acordo aos dados  */
	/**
	 * @param  {object} prop
	 * @param  {valor} value
	 */
	static async getAdminBy(prop, value) {
		try {
			const url = `${URLS.ACCOUNTS}/${value}/`;
			const params = { by: prop };
			const response = await BaseAPI.get(url, params);
			if (response.status === 200) return response.data;
			return { error: "Falha ao buscar admin" };
		} catch (e) {
			console.log(e.message);
			return { error: e.message };
		}
	}

	/**
	 * Busca um usuário admin por username
	 * @param  {string} userName username do usuário a ser buscado
	 */
	static async getUserAdmin(userName) {
		console.log("caiu aqui");
		try {
			const url = `${URLS.ACCOUNTS}/${userName}`;
			const response = await BaseAPI.get(url);
			if (response.data.status) {
				return { error: "Falha ao buscar usuário" };
			}
			return response.data;
		} catch (e) {
			console.log(e.message);
			return { error: "Falha ao buscar usuário" };
		}
	}

	/**Busca todos os admins */
	static async getAllAdministrators(params) {
		try {
			const response = await BaseAPI.get(URLS.ACCOUNTS, params);
			if (response.status === 200) return response.data;
			return { error: "Falha ao buscar usuários" };
		} catch (e) {
			console.log(e.message);
			return { error: "Falha ao buscar usuários" };
		}
	}


	/**Busca todos os admins */
	static async changePassword(data) {
		const url = '/manager/administrator/change-password';

		try {
			const response = await BaseAPI.post(url, data);
			if (response.status === 200) return response.data;
			return { error: "Falha ao alterar senha" };
		} catch (e) {
			console.log(e.message);
			return { error: "Falha ao alterar senha" };
		}
	}
}

export default AdministradorAPI;
