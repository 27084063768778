import BaseAPI, { URLS } from "./BaseAPI";

class CustomerAPI {
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * Request que cria novo client.
   * @param  {Object} data - Objeto do usuário que será criado
   */
  static async saveClient(data) {
    try {
      const response = await BaseAPI.post(URLS.CUSTOMERS, data);
      if (response.status === 201) return response;
      return { error: "Erro inesperado ao cadastrar o usuário" };
    } catch (e) {
      return this._exception(e, "Falha ao cadastrar o usuário");
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário que será editado
   * @param {object} data dados do usuário que será editado
   */
  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.CUSTOMERS}/${uuid}`, data);
      if (response.status === 200) return response.data;
      return { error: "Falha ao atualizar informações do usuário" };
    } catch (e) {
      console.log(e);
      return { error: "Falha ao atualizar informações do usuário" };
    }
  }

  /**
   * Busca um usuário ( admin ou customer )
   * @param  {string} uuid
   */
  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.CUSTOMERS}/${uuid}`);
      if (response.data.status) {
        return { error: "Falha ao buscar usuário" };
      }
      return response.data;
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao buscar usuário" };
    }
  }

  /**
   * Busca lista de usuários
   * @param  {params} params parametros de paginação
   */
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`${URLS.CUSTOMERS}/find/`, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar usuários" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao buscar usuários" };
    }
  }

  /**
   * Deleta o tesouro
   * @param  {string} uuid
   */
  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.CUSTOMERS}/${uuid}`);
      if (response.status === 204 || response.status === 200)
        return response.data;
      return {
        error: "Não foi possível deletar o usuário",
      };
    } catch (e) {
      return this._exception(e, "Não foi possível deletar o usuário");
    }
  }
}

export default CustomerAPI;
