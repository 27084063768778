import BaseAPI, { URLS } from "~/services/BaseAPI";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";

/**
 * @function getProducts função para buscar os produtos
 * @param {String} filters filtros para busca de produto
 */

class ReportsAPI {
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**Busca todos os tesouros e usuários */
  static async getListAllUsers(params) {
    const url = `/manager/ratings/treasure-customer`;

    try {
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar usuários" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao buscar usuários" };
    }
  }

  /**Busca o total de serviços */
  static async getListServices(params, filter) {
    // /manager/ratings/categories?state=SP&city=MogiGuaçu&region=true&category=Comunicação

    const url = `/manager/ratings/categories`;

    try {
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar usuários" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao buscar usuários" };
    }
  }

  /**Busca as cidades mais pesquisadas sem Tesouro */
  static async getMostSearchedRegions(params) {
    const url = `/manager/ratings/address-without-treasure`;

    try {
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar usuários" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao buscar usuários" };
    }
  }

  /**Busca as regiões por ranking */
  static async getRatingRanking(params) {
    const url = `/manager/ratings/`;

    try {
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar usuários" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao buscar usuários" };
    }
  }

  /**
   * Busca todas as cidades por estado
   * @param  {params} params parametros de paginação
   */
  static async getAdressCity(params) {
    try {
      const response = await BaseAPI.get(`/manager/address/${params}/cities`);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar cidades" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao buscar cidades" };
    }
  }

  static async getNeighborhood(state,city) {
    try {
      const response = await BaseAPI.get(`/manager/address/${state}/${city}/blocks`);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar bairro" };
    } catch (e) {
      console.log(e.message);
      return { error: "Falha ao buscar bairro" };
    }
  }
}

export default ReportsAPI;
